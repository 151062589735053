import { type IReCaptchaComposition, useReCaptcha } from 'vue-recaptcha-v3'

/**
 * The exported executeRecaptcha function allows
 * you to execute reCAPTCHA actions
 * and retrieve the reCAPTCHA token along with the header options
 * to be used in subsequent requests.
 * @see https://dev.to/fitrakun/integrating-nuxt-3-with-recaptcha-v3-for-token-handling-2dp0
 */
export function useGoogleRecaptcha() {
  const recaptchaInstance = ref<IReCaptchaComposition | undefined>()

  onMounted(() => {
    recaptchaInstance.value = useReCaptcha()
  })

  async function executeRecaptcha(action: string) {
    if (!recaptchaInstance.value) {
      throw new Error('Recaptcha instance is not available')
    }

    /**
     * Wait for the recaptchaInstance to be loaded
     * by calling the recaptchaLoaded method.
     * This ensures that the reCAPTCHA library is fully loaded
     * and ready to execute reCAPTCHA actions.
     */
    await recaptchaInstance.value.recaptchaLoaded()

    return recaptchaInstance.value.executeRecaptcha(action)
  }

  return { executeRecaptcha }
}
