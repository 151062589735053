/**
 * @description Converts cents to dollars and formats the price in AUD
 * @param cents {number} - The price in cents
 * @param withCents {boolean} - Whether to include cents in the formatted price
 * @returns {string} - The price in dollars
 */
export function formatPrice(cents: number, withCents?: boolean): string {
  const formatter = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: withCents ? 2 : 0,
    maximumFractionDigits: withCents ? 2 : 0
  })
  return formatter.format(cents / 100)
}
