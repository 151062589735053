<script lang="ts" setup>
  import { Vue3Lottie } from 'vue3-lottie'

  const props = withDefaults(
    defineProps<{
      size?: string
      variant?: 'light' | 'dark' | 'red'
    }>(),
    {
      size: '1.25rem',
      variant: 'dark'
    }
  )

  async function getAnimationAsset() {
    switch (props.variant) {
      case 'light':
        return await import('~/assets/animations/loading-button-light.json')
      case 'dark':
        return await import('~/assets/animations/loading-button-dark.json')
      case 'red':
        return await import('~/assets/animations/loading-button-red.json')
      default:
        return await import('~/assets/animations/loading-button-light.json')
    }
  }

  const animationData = ref()

  onBeforeMount(async () => {
    const res = await getAnimationAsset()

    animationData.value = res.default
  })
</script>

<template>
  <Vue3Lottie
    v-if="animationData"
    :animation-data="animationData"
    :height="size"
    :width="size"
    class="c-loading-animation"
    role="progressbar"
  />
</template>

<style lang="scss" scoped>
  .c-loading-animation {
    display: inline-block;
  }
</style>
